.YourCoursesSection__Header {
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
}

.YourCoursesSection__Header h3 {
  margin-left: 10px;
}

.YourCoursesSection__ClearButton {
  cursor: pointer;
  margin-left: 10px;
  font-size: 14px;
  margin-bottom: 15px;
}

.YourCoursesSection__ClearButton:hover {
  text-decoration: underline;
}
