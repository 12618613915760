.CourseCard__Container {
  border-radius: 6px;
  box-shadow: 0 2px 15px 0 rgba(99, 156, 191, 0.3);
  width: 30%;
  display: flex;
  margin: 10px 5px;
  min-width: 280px;
  text-decoration: none;
  transition: 0.2s ease;
  background-color: white;
  height: 85px;
}

.CourseCard__Container p {
  margin: auto;
  width: 75%;
  padding: 0px 10px;
  font-family: "Muli", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  text-align: left;
  letter-spacing: normal;
  color: #4a4a4a;
}

.CourseCard__Container p:hover {
  color: #336bbb;
}

.CourseCard__Image {
  width: 25%;
  object-fit: cover;
  overflow: hidden;
  background-position: center;
  border-radius: 6px 0px 0px 6px;
}

@media screen and (max-width: 920px) {
  .CourseCard__Container {
    width: 48%;
  }
}

@media screen and (max-width: 600px) {
  .CourseCard__Container {
    width: 98%;
  }
}
