.TutoringPayment__Container {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 2px 15px 0 rgba(99, 156, 191, 0.3);
  background-color: white;
  padding: 12px;
  width: 100%;
  list-style-type: none;
}

.TutoringPayment__Container p {
  padding: 0px 20px 0px 12px;
  line-height: 1.5;
}

.TutoringPayment__Button {
  width: 200px;
  height: 50px;
  align-self: center;
  margin: 20px 0px;
}

@media only screen and (max-width: 600px) {
  .TutoringPayment__Container {
    flex-direction: column;
  }

  .TutoringPayment__Container p {
    padding: 0px;
  }

  .TutoringPayment__Button {
    width: 100%;
  }
}
