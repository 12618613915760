.BodyContainer__Outer {
  display: flex;
  justify-content: center;
  padding: 0px 5px;
}

.BodyContainer__Inner {
  max-width: 800px;
  padding: 0px 25px;
}

@media screen and (max-width: 650px) {
  .BodyContainer__Inner {
    padding: 0px 8px;
  }
}
