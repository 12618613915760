.PillMenu__Container {
  display: flex;
}

.PillMenu__Item {
  padding: 6px 12px;
  margin-right: 8px;
  cursor: pointer;
  border-radius: 16px;
  font-size: 14px;
}

.PillMenu__Item:hover {
  background-color: #f5f5f5;
}

.PillMenu__Active {
  background-color: #b8daff;
}

.PillMenu__Active:hover {
  background-color: #b8daff;
}
