.InstructionsBox__container {
  border-radius: 4px;
  border: dashed 1px #c7c7c7;
  padding: 15px;
  max-width: 300px;
}

.InstructionsBox__logoContainer {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.InstructionsBox__logo {
  height: 25px;
}

.InstructionsBox__title {
  text-align: left;
}

.qrDesc {
  margin: 10px 0px;
}
.InstructionsBox__container ol {
  padding: 0px 20px;
  font-size: 13px;
}

.InstructionsBox__container ol li {
  margin: 15px 0px;
}

.InstructionsBox__impText {
  font-weight: bold;
  font-size: 16px;
  color: #64bff4;
}
