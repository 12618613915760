.PrintJoinClassInstructions__BodyInnerContainer {
  border-radius: 6px;
  box-shadow: 0 2px 15px 0 rgba(99, 156, 191, 0.3);
  background-color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}

.PrintJoinClassInstructions__Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid;
}
.PrintJoinClassInstructions__Button {
  margin-left: 15px;
  height: 40px;
  width: 175px;
}

@media screen and (max-width: 500px) {
  .PrintJoinClassInstructions__Header {
    flex-direction: column;
    justify-content: flex-start;
  }
  .PrintJoinClassInstructions__Button {
    width: 100%;
    margin-left: 0px;
  }
}

@media screen and (max-width: 650px) {
  .PrintJoinClassInstructions__BodyInnerContainer {
    padding: 10px 12px;
  }
}

.PrintJoinClassInstructions__InstructionCardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media print {
  .PrintJoinClassInstructions__PrintContainer {
    background-color: white;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 15px;
  }
}
