.HeaderSection__NavbarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
  margin: 10px 0px;
}
.HeaderSection__NoSignup {
  justify-content: center;
}

@media screen and (max-width: 450px) {
  .HeaderSection__NavbarContainer {
    padding: 0px 8px;
  }
}
.HeaderSection__Logo {
  height: 35px;
}

.HeadSection__LogoContainer {
  height: fit-content;
}

.HeaderSection__Button {
  background-color: white;
  color: #333333;
  height: 40px;
  border-radius: 4px;
  box-shadow: 0 5px 20px 2px rgba(155, 155, 155, 0.5);
  padding: 0px 25px;
  height: inherit;
  font-size: 14px;
  cursor: pointer;
  border: none;
  transition: 0.2s;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.HeaderSection__Button:hover {
  background-color: #e2e4e6;
  box-shadow: 0 5px 20px 2px rgba(155, 155, 155, 0.5);
}
