.TopicSearchPage__SearchResultContainer {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 2px 15px 0 rgba(99, 156, 191, 0.3);
  background-color: white;
  padding: 10px;
  list-style-type: none;
}
.TopicSearchPage__SearchResultContainer li {
  margin: 10px 5px;
}

.TopicSearchPage__Input {
  border-radius: 4px;
  border: solid 1px #b9bdcc;
  padding: 15px;
  font-size: 20px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.TopicSearchPage__Input:focus {
  box-shadow: 0 2px 10px 0 rgba(155, 155, 155, 0.5);
  outline-width: 0;
}

.TopicSearchPage__BodyInnerContainer {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 700px;
  min-height: 1000px;
}

@media only screen and (max-width: 760px) {
  .TopicSearchPage__BodyInnerContainer {
    width: 100%;
  }
}
