.YourSchoolPage__InnerContainer {
  max-width: 1100px;
}

.YourSchoolPage__InnerContainer h2 {
  text-align: center;
  color: white;
  margin-top: 0px;
}

.YourSchoolPage__InnerContainer h4 {
  color: white;
}

.YourSchoolPage__Link {
  color: #64bff4;
}

.YourSchoolPage__SelectAgeGroupContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.YourSchoolPage__SelectAgeGroup {
  color: white;
  width: 30%;
  padding: 40px;
  border: solid white;
  cursor: pointer;
  margin: 10px 5px;
  min-width: 280px;
}

.YourSchoolPage__SelectAgeGroup:hover {
  color: #336bbb;
  background-color: white;
}

@media screen and (max-width: 920px) {
  .YourSchoolPage__SelectAgeGroup {
    width: 48%;
  }
}

@media screen and (max-width: 600px) {
  .YourSchoolPage__SelectAgeGroup {
    width: 98%;
  }
}
