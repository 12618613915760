.ShareLinkSection__Container {
  display: flex;
}

.ShareLinkSection__TextContainer {
  display: flex;
  border-radius: 4px;
  border: dashed 2px #c7c7c7;
  height: 40px;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 5px;
  font-size: 13px;
  cursor: pointer;
  padding: 3px 8px;
}

.ShareLinkSection__Error {
  background-color: #f2dede;
  border-color: #ebcccc;
  color: #a94442;
  padding: 20px 10px;
  border-radius: 2px;
}
