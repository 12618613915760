/* The container */
.SubjectCheckBoxRow__Container {
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 0px;
}

.SubjectCheckBoxRow__Container:hover {
  background-color: #f5f5f5;
}

/* Hide the browser's default checkbox */
.SubjectCheckBoxRow__Container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  height: 22px;
  width: 22px;
  min-width: 22px;
  border-radius: 2px;
  border: solid 1px #969696;
  background-color: #ffffff;
  margin-right: 8px;
}

/* When the checkbox is checked, add a blue background */
.SubjectCheckBoxRow__Container input:checked ~ .checkmark {
  border: solid 1px #25b999;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.SubjectCheckBoxRow__Container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.SubjectCheckBoxRow__Container .checkmark:after {
  width: 5px;
  left: 8px;
  height: 12px;
  border: solid #3bca9a;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
