.Spinner__Container {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-bar {
  display: inline-block;
  width: 4px;
  height: 18px;
  border-radius: 4px;
  animation: loading 1s ease-in-out infinite;
  margin: 0px 2px;
  background-color: white;
}
.loading-bar:nth-child(1) {
  animation-delay: 0;
}
.loading-bar:nth-child(2) {
  animation-delay: 0.09s;
}
.loading-bar:nth-child(3) {
  animation-delay: 0.18s;
}
.loading-bar:nth-child(4) {
  animation-delay: 0.27s;
}

@keyframes loading {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1, 2.2);
  }
  40% {
    transform: scale(1);
  }
}
