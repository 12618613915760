.Button__Container {
  border-radius: 4px;
  box-shadow: 0 5px 20px 2px rgba(155, 155, 155, 0.5);
  background-color: #64bff4;
  color: #ffffff;
  padding: 0px 25px;
  height: inherit;
  font-size: 14px;
  cursor: pointer;
  border: none;
  transition: 0.2s;
}

.Button__Container:hover {
  box-shadow: 0 2px 6px 1px rgba(155, 155, 155, 0.5);
  background-color: #5aacdb;
}

.Button__Disabled {
  background-color: #cccccc;
  color: #666666;
}

.Button__Disabled:hover {
  box-shadow: 0 5px 20px 2px rgba(155, 155, 155, 0.5);
  background-color: #cccccc;
  color: #666666;
}
